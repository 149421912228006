.name-field{
  padding: 40px 0 20px;
  @include breakpoint(tablet){
    padding: 60px 0 20px;
  }
  input[type=text]{
    width: 100%;
    background: transparent;
    border:none;
    border-bottom: 1px solid white;
    color: white;
    text-align: center;
    outline: none;
    font-size: $xl;
    padding: 10px;
    @include breakpoint(tablet){
      font-size: 64px;
      padding: 20px;
    }
    &::-webkit-input-placeholder { 
      color: white;
      opacity: 1;
    }
    &:focus{
      &::-webkit-input-placeholder { 
        color: transparent;
      }
    }
  }
}

