.logo-generator{
  .hero-logo{
    width: 100%;
    max-width: 482px;
    margin-top: 82px;
  }
}

.generated-logo{
  &__wrapper{
    border-bottom: 1px solid $border-grey;
    padding: 40px;
    margin-bottom: 80px;
    text-align: center;
  }
  &__logo{
    width: 100%;
    max-width: 900px;
  }
  &__options{
    margin: 0 auto;
    padding: 40px 20px;
    width: 100%;
    max-width: 700px;
    @include breakpoint(tablet){
      display: flex;
      justify-content: space-between;
    }
    .op-container{
      text-align: center;
      @include breakpoint(tablet){
        display: flex;
        text-align: left;
      }
      padding: 0 20px 20px;;
      span{
        font-size: $med;
        font-family: Arial, Helvetica, sans-serif;
        @include breakpoint(tablet){
          font-size: $xl;
        }
      }
      .icon{
        margin: 0 0 0 20px;
      }
    }
  }
}