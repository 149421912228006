.wrapper{
  background-color: #333;
  background-image: url("/images/bg-texture-min.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; 
  background-attachment: fixed;
  min-height: 100vh;
  overflow: hidden;
}

.container{
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px 40px;
  min-height: calc(100vh - 250px);
  text-align: center;
  &.logo-generator{
    min-height: calc(100vh - 125px);
  }
}