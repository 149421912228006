//Color Palette
$border-grey: #979797;
$gold: #D6BF53;

//Sizes
$h1: 48px;
$h2: 32px;
$xl: 24px;
$lg: 18px;
$med: 14px;
$sm: 12px; 


//Motion
$nice-bezier: cubic-bezier(0.165, 0.84, 0.44, 1);

