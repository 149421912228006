.logo-selection{
  &__wrapper{
    @include breakpoint(tablet){
      padding: 40px;
    }
    border-top: 1px solid $border-grey;
    h3{
      font-size: $xl;
    }
  }
  &__logos{
    width: 100%;
    @include breakpoint(tablet){
      padding: 30px 0px;
      display: flex;
    }
    a{
      display: block;
      width: 100%;
      text-align: center;
      padding: 10px 0;
      @include breakpoint(tablet){
        padding: 20px;
        width: 50%;
      }
      .logo{
        max-height:170px;
        max-width: 100%;
      }
    }
  }
}