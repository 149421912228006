html, body {
  font-family: 'Palanquin Dark', Arial, Helvetica, sans-serif;  
  color: white;
  @media 
  (-webkit-min-device-pixel-ratio: 2), 
  (min-resolution: 192dpi) { 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: greyscale;
  }  
}

h1, h2, h3, h4, h5, h6, p {
  font-family: 'Palanquin Dark', Arial, Helvetica, sans-serif; 
  font-weight: 500;
}

.headline{
  font-size: $xl;
  @include breakpoint(tablet){
  font-size: 64px;
  }
}

a{
  text-decoration: none;
  color: white;
}