.btn{
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  border: 0;
  outline: none;
  border-radius: 25px;
  padding: 10px 15px;
  min-width: 220px;
  background: $gold;
  &:hover{
    cursor: pointer;
  }
  backface-visibility: hidden;
  &:active{
    background: darken($gold, 5%);
  }
}